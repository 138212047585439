import React from "react";

//reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/Navbars/DropdownScrollNavbar.js";
//import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import ActiveEventsHeader from "../components/Headers/ActiveEventsHeader.js";
import Footer from "../components/Footers/FooterBlack.js";
import rest from "API/api";

// core components

function ActiveEvents() {
  const AVPAImage = require("assets/img/presentation-page/AVPA Logo.png");
  const [eventList,setEventList] = React.useState([]);
  // slider states and functions
  const [sliderMin, setSliderMin] = React.useState(100);
  const [sliderMax, setSliderMax] = React.useState(880);
  //Lets load all Collections
  React.useEffect(() => {
    //Lets load all Collections
    var tempURL = rest.events;
    fetch(tempURL, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json;charset=UTF-8',
        'x-api-key': rest.apiKey
      })
    })
        .then(response => response.json())
        .then(data => {
          //debugger;
          setEventList(data);
          // turn the JSON into a string then into an JS array
        })
        .catch(error => console.error(error));
      document.body.classList.add("sidebar-collapse");
      document.documentElement.classList.remove("nav-open");
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      return function cleanup() {
          document.body.classList.remove("ecommerce-page");
          document.body.classList.remove("sidebar-collapse");
      };

  },[]);


  const staticEventBody =
      (
          <tr>
              <td className="td-actions text-right">
                  <Button
                      className="btn-round"
                      color="warning"
                      href="https://avpamerica.com/VA-Beach-Volleyball-Schedule.aspx" target="_blank"
                  >
                      Sign up!
                  </Button>
              </td>
              <td><h6 className="category">AVP America Events - Select CENTRAL Region</h6></td>
              <td><h6 className="category">See Schedule</h6></td>
              <td><h6 className="category">See Schedule</h6></td>
              <td><h6 className="category">See Schedule</h6></td><br></br>
          </tr>
      );

const eventBody =
    eventList.map((item, index) => {
      if (item.Enabled) {
        var StartDate = new Date(item.StartDate);
        var EndDate = new Date(item.EndDate);
        const startDate = StartDate.toLocaleDateString('en-US') + " @ " + StartDate.toLocaleTimeString('en-US');
        const endDate = EndDate.toLocaleDateString('en-US') + " @ " + EndDate.toLocaleTimeString('en-US');
        return (
            <tr>
                <td className="td-actions text-right">
                    <Button
                        className="btn-round"
                        color="warning"
                        href="https://dashboard.thedeepvbc.com/#/register" target="_blank"
                    >
                        Sign up!
                    </Button>
                    {/*
                <Button color="info" type="button">
                  <i className="now-ui-icons users_single-02"></i>
                </Button>
                <Button color="success" type="button">
                  <i className="now-ui-icons ui-2_settings-90"></i>
                </Button>
                <Button color="danger" type="button">
                  <i className="now-ui-icons ui-1_simple-remove"></i>
                </Button>
                */}
                </td>
                <td><h6 className="category">{item.Title}</h6></td>
                {/*}
              <td>{item.Type}</td>
              <td>{item.Location}</td>
              */}
                <td><h6 className="category">{StartDate.toLocaleDateString('en-US')}</h6></td>
              <td><h6 className="category">{StartDate.toLocaleTimeString('en-US')}</h6></td>
              <td>
              {(item.MaxRegistrations <= item.Registrations.length) ?
                  <large className="text-danger"><b><h6 className="category text-danger"> {item.Registrations.length} -
                    FULL</h6></b><br></br></large>
                  :
                  <large className="text-success"><b> <h6 className="category text-success">Available</h6></b><br></br></large>
              }
              </td>

            </tr>
        );
      }
    });

const eventTable = (<Col className="ml-auto mr-auto" md="14">
  <Card className="card-blog">
    <CardBody>
      <h4 className="category text-info">Active Events List</h4>
    <Table>
      <thead>
      <tr>
      <th className="text-center">Actions</th>
        <th>Title</th>
          {/*
        <th>Type</th>
        <th>Location</th>
        */}
        <th>Start Date</th>
        <th>Start Time</th>
        <th>Status</th>

      </tr>
      </thead>
      <tbody>
      {staticEventBody}
      {eventBody}
      </tbody>
    </Table>
    </CardBody>
  </Card>


  </Col>);

  return (
    <>
      <DropdownScrollNavbar />
      <div className="wrapper">
        <ActiveEventsHeader />
        <div className="main">
      <div className="cd-section" id="blogs">
        <div className="blogs-5" data-background-color="gray">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                {/*
                <h2 className="title">Our Events</h2>
                */}
                {eventTable}
                <Row>
                    {/* Manual Card to link to AVPAmerica Registration site */}
                    <Col md="6">
                        <Card className="card-blog">
                            <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="img rounded"
                                        src= {AVPAImage}
                                        width="300"
                                        height="300"
                                    ></img>
                                </a>
                            </div>
                            <CardBody>
                                <h6 className="category text-info">Event Details</h6>
                                <CardTitle tag="h5">
                                    AVP America Events<br></br>
                                </CardTitle>
                                <p className="card-description">
                                    Registration for all AVP America Events
                                </p>
                                <p>
                                    Type: <b>See Schedule</b><br></br>
                                    Location: <b>Select CENTRAL Region</b><br></br>
                                    Start Date: <b>See Event</b><br></br>
                                    End Date: <b>See Even t</b><br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                </p>
                                <CardFooter>
                                    {/*
                                              <div className="author">

                                                <img
                                                  alt="..."
                                                  className="avatar img-raised"
                                                  src={require("assets/img/julie.jpg")}
                                                ></img>

                                                <span>{item.Type}</span>
                                              </div>
                                              */}

                                    {/*}
                                              <div className="stats stats-right">
                                                  <i className="now-ui-icons tech_watch-time"></i>5
                                                min registration
                                              </div>*/}
                                    <div className="stats stats-right">
                                        <Button
                                            className="btn-round"
                                            color="warning"
                                            href="https://avpamerica.com/VA-Beach-Volleyball-Schedule.aspx" target="_blank"
                                        >
                                            Sign up!
                                        </Button>
                                    </div>

                                </CardFooter>
                            </CardBody>
                        </Card>
                    </Col>
                    {(eventList.length !== 0) ? (
                            eventList.map((item, index) => {
                              if(item.Enabled) {
                                var StartDate = new Date(item.StartDate);
                                var EndDate = new Date(item.EndDate);
                                const startDate = StartDate.toLocaleDateString('en-US') + " @ " + StartDate.toLocaleTimeString('en-US');
                                const endDate = EndDate.toLocaleDateString('en-US') + " @ " + EndDate.toLocaleTimeString('en-US');
                                return (
                                    <Col md="6">
                                      <Card className="card-blog">
                                        <div className="card-image">
                                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                className="img rounded"
                                                src={item.Image}
                                            ></img>
                                          </a>
                                        </div>
                                        <CardBody>
                                          <h6 className="category text-info">Event Details</h6>
                                          <CardTitle tag="h5">
                                            {item.Title}<br></br>
                                          </CardTitle>
                                          <p className="card-description">
                                            {item.Description}
                                          </p>
                                          <p>
                                            Type: <b>{item.Type}</b><br></br>
                                            Location: <b>{item.Location}</b><br></br>
                                            Start Date: <b>{startDate} MST</b><br></br>
                                            End Date: <b>{endDate} MST</b><br></br>
                                            Max Registrations: <b>{item.MaxRegistrations}</b><br></br>
                                            Current Registrations:
                                            {(item.MaxRegistrations <= item.Registrations.length) ?
                                                <large className="text-danger"><b> {item.Registrations.length} -
                                                  FULL</b><br></br></large>
                                                :
                                                <large className="text-success"><b> Still
                                                  Spots Available</b><br></br></large>
                                            }
                                            Age Restrictions: <b>{item.AgeRestriction}</b><br></br>
                                            Gender Restrictions: <b>{item.GenderRestriction}</b><br></br>
                                          </p>
                                          <CardFooter>
                                            {/*
                                              <div className="author">

                                                <img
                                                  alt="..."
                                                  className="avatar img-raised"
                                                  src={require("assets/img/julie.jpg")}
                                                ></img>

                                                <span>{item.Type}</span>
                                              </div>
                                              */}

                                            {/*}
                                              <div className="stats stats-right">
                                                  <i className="now-ui-icons tech_watch-time"></i>5
                                                min registration
                                              </div>*/}
                                            <div className="stats stats-right">
                                              <Button
                                                  className="btn-round"
                                                  color="warning"
                                                  href="https://dashboard.thedeepvbc.com/#/register" target="_blank"
                                              >
                                                Sign up!
                                              </Button>
                                            </div>

                                          </CardFooter>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                )
                            }
                              }))

                          :
                          (
                              <div></div>
                          )}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ActiveEvents;
