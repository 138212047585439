import React from "react";
import DropdownScrollNavbar from "components/Navbars/DropdownScrollNavbar.js";
import Footer from "components/Footers/FooterBlack.js";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Teams() {
  React.useEffect(() => {
    document.body.classList.add("pricing");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("pricing");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DropdownScrollNavbar />
      <div className="cd-section" id="teams">
        <div
          className="team-5 section-image"
          style={{
            backgroundImage: "url(" + require("assets/img/presentation-page/OutsideSandCourt4.jpg") + ")",
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">The Deep Management Team</h2>
                <h4 className="description">
                  Meet our club management team, we are small but deeply care about our athletes, their families and their
                  investments in themselves to grow their game.
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-profile">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/presentation-page/rue.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <CardTitle tag="h3">Rue Green</CardTitle>
                    <h6 className="category text-info">Founder / Owner</h6>
                    <p className="card-description">
                      The Deeper the sand, the better the plan; Rue is our owner, founder, a coach and responsible for
                      all the software development supporting our club and its members.
                    </p>
                    <CardFooter>
                      <Button
                        className="btn-icon btn-neutral btn-round"
                        color="info"
                        href="http://www.linkedin.com/in/guruegreen"
                        target="_blank">
                        <i className="fab fa-linkedin"></i>
                      </Button>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/presentation-page/marcy.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <CardTitle tag="h4">Marcy Green</CardTitle>
                    <h6 className="category text-info">Director</h6>
                    <p className="card-description">
                      Marcy is definitely Rue's better half; Marcy is our cherished club director helping us organize
                      and run our club.  She has years of club volleyball experience under her belt!
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/presentation-page/jake3.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <CardTitle tag="h4">Jake Green</CardTitle>
                    <h6 className="category text-info">Facilities / Coach</h6>
                    <p className="card-description">
                      Jake handles our facilities, taking care of the buildings, the sand and our courts.  Jake also is
                      one of our strongest sand coaches at The Deep.
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Teams;
