import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip, NavLink,
} from "reactstrap";

function DropdownScrollNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState(" bg-primary");
  const [buyButtonColor, setBuyButtonColor] = React.useState("neutral");
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
        setBuyButtonColor("info");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor(" bg-primary");
        setBuyButtonColor("neutral");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top" + navbarColor} color="white" expand="lg">
        <Container>

          <div className="navbar-translate">
            <button
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                  setCollapseOpen(!collapseOpen);
                }}
                aria-expanded={collapseOpen}
                className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    href="#pablo"
                    id="navbarDropdownMenuLink1"
                    nav
                    onClick={(e) => e.preventDefault()}
                >
                  <i className="now-ui-icons sport_user-run"></i>
                  <p>Our Club</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                  <DropdownItem to="/philosphy" tag={Link}>
                    <i className="now-ui-icons education_hat"></i>

                    Philosphy
                  </DropdownItem>
                  <DropdownItem to="/facilities" tag={Link}>
                    <i className="now-ui-icons business_bank"></i>
                    Facilities
                  </DropdownItem>
                  <DropdownItem to="/pricing" tag={Link}>
                    <i className="now-ui-icons business_money-coins"></i>
                    Services and Prices
                  </DropdownItem>
                  <DropdownItem to="/presenation" tag={Link}>
                    <i className="now-ui-icons education_atom"></i>

                    Return Home
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    href="#pablo"
                    id="navbarDropdownMenuLink"
                    nav
                    onClick={(e) => e.preventDefault()}
                >
                  <i
                      aria-hidden={true}
                      className="now-ui-icons sport_trophy"
                  ></i>
                  <p>Our Staff</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem to="/management" tag={Link}>
                    <i className="now-ui-icons design_vector"></i>
                    Management Team
                  </DropdownItem>
                  <DropdownItem to="/coaches" tag={Link}>
                    <i className="now-ui-icons business_badge"></i>
                    Coaches
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    href="#pablo"
                    id="navbarDropdownMenuLink"
                    nav
                    onClick={(e) => e.preventDefault()}
                >
                  <i
                      aria-hidden={true}
                      className="now-ui-icons education_agenda-bookmark"
                  ></i>
                  <p>Events</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem tag={Link} to="/activeevents">
                    <i className="now-ui-icons education_agenda-bookmark"></i>
                    Active Events
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/practicecalendar">
                    <i className="now-ui-icons ui-1_calendar-60"></i>
                    Practice Calendar
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/tourneycalendar">
                    <i className="now-ui-icons ui-1_calendar-60"></i>
                    Tourney/League Calendar
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/dropincalendar">
                    <i className="now-ui-icons ui-1_calendar-60"></i>
                    Drop In Calendar
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    href="#pablo"
                    id="navbarDropdownMenuLink"
                    nav
                    onClick={(e) => e.preventDefault()}
                >
                  <i
                      aria-hidden={true}
                      className="now-ui-icons files_single-copy-04"
                  ></i>
                  <p>News</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem tag={Link} to="/announcements">
                    <i className="now-ui-icons files_paper"></i>
                    Announcements
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/blogs">
                    <i className="now-ui-icons education_paper"></i>
                    Blogs
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/projects">
                    <i className="now-ui-icons location_map-big"></i>
                    Projects
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    href="#pablo"
                    id="navbarDropdownMenuLink"
                    nav
                    onClick={(e) => e.preventDefault()}
                >
                  <i
                      aria-hidden={true}
                      className="now-ui-icons design_image"
                  ></i>
                  <p>Gallery</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem tag={Link} to="/cohortgallery">
                    <i className="now-ui-icons emoticons_satisfied"></i>
                    Cohort Gallery
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/tourneygallery">
                    <i className="now-ui-icons design_vector"></i>
                    Tourney Gallery
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/generalgallery">
                    <i className="now-ui-icons media-1_album"></i>
                    General Gallery
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                    className="active"
                    href="https://thedeepvbc.square.site"
                >
                  <i className="now-ui-icons shopping_cart-simple"></i>
                  Store
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                    className="active"
                    href="/contactus"
                >
                  <i className="now-ui-icons location_pin"></i>
                  Contact Us
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default DropdownScrollNavbar;
