/*!

=========================================================
* Now UI Kit PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/react-demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// pages
import AboutUs from "views/examples/AboutUs.js";
import BlogPost from "views/examples/BlogPost.js";
import BlogPosts from "views/examples/BlogPosts.js";
import ContactUs from "views/Contactus.js";
import Ecommerce from "views/examples/Ecommerce.js";
import Index from "views/Index.js";
import LandingPage from "views/examples/LandingPage.js";
import LoginPage from "views/examples/LoginPage.js";
import NucleoIcons from "views/NucleoIcons.js";
import Presentation from "views/Presentation.js";
import PracticeCalendar from "views/PracticeCalendar";
import TourneyCalendar from "views/TourneyCalendar";
import DropinCalendar from "views/DropinCalendar";
import Philosphy from "views/Philosphy.js";
import Facilities from "views/Facilities.js";
import Pricing from "views/Pricing.js";
import Management from "views/Management.js";
import Coaches from "views/Coaches.js";
import Announcements from "views/Announcements.js";
import CohortGallery from "views/CohortGallery.js";
import TourneyGallery from "views/TourneyGallery.js";
import GeneralGallery from "views/GeneralGallery.js";
import ActiveEvents from "views/ActiveEvents.js";
import Blogs from "views/Blogs.js";
import Projects from "views/Projects.js";
import Store from "views/Store.js";
import Contactus from "views/Contactus.js";
import Underconstruction from "views/Underconstruction.js";


import ProductPage from "views/examples/ProductPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import Sections from "views/Sections.js";
import SignupPage from "views/examples/SignupPage.js";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/*
      <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
      <Route path="/blog-post" render={(props) => <BlogPost {...props} />} />
      <Route path="/blog-posts" render={(props) => <BlogPosts {...props} />} />
      <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
      <Route path="/e-commerce" render={(props) => <Ecommerce {...props} />} />
      */}
      <Route path="/index" render={(props) => <Presentation {...props} />} />
      <Route path="/practicecalendar" render={(props) => <PracticeCalendar {...props} />} />
      <Route path="/tourneycalendar" render={(props) => <TourneyCalendar {...props} />} />
      <Route path="/dropincalendar" render={(props) => <DropinCalendar {...props} />} />
      <Route path="/philosphy" render={(props) => <Philosphy{...props} />} />
      <Route path="/facilities" render={(props) => <Facilities {...props} />} />
      <Route path="/pricing" render={(props) => <Pricing {...props} />} />
      <Route path="/management" render={(props) => <Management {...props} />} />
      <Route path="/coaches" render={(props) => <Coaches {...props} />} />
      <Route path="/announcements" render={(props) => <Announcements {...props} />} />
      <Route path="/blogs" render={(props) => <Underconstruction {...props} />} />
      <Route path="/projects" render={(props) => <Underconstruction {...props} />} />
      <Route path="/cohortgallery" render={(props) => <CohortGallery {...props} />} />
      <Route path="/tourneygallery" render={(props) => <TourneyGallery {...props} />} />
      <Route path="/generalgallery" render={(props) =><GeneralGallery {...props} />} />
      <Route path="/store" render={(props) => <Underconstruction{...props} />} />
      <Route path="/activeevents" render={(props) => <ActiveEvents {...props} />} />
      <Route path="/contactus" render={(props) => <ContactUs {...props} />} />

      {/*
      <Route
        path="/landing-page"
        render={(props) => <LandingPage {...props} />}
      />
      <Route path="/login-page" render={(props) => <LoginPage {...props} />} />
      <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      />
      */}
      <Route
        path="/presentation"
        render={(props) => <Presentation {...props} />}
      />
      {/*
      <Route path="/pricing" render={(props) => <Pricing {...props} />} />
      <Route
        path="/product-page"
        render={(props) => <ProductPage {...props} />}
      />
      <Route
        path="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      />
      <Route path="/sections" render={(props) => <Sections {...props} />} />
      <Route path="/sign-up" render={(props) => <SignupPage {...props} />} />
      */}
      <Redirect to="/presentation" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
