import React from "react";

//core components
//import DropdownScrollNavbar from "components/Navbars/DropdownScrollNavbar.js";
import DropdownScrollNavbar from "../components/Navbars/DropdownScrollNavbar.js";
// core components
import TourneyGalleryHeader from "components/Headers/TourneyGalleryHeader.js";
import Footer from "components/Footers/FooterBlack.js";
import Select from "react-select";
import rest from "API/api";
// reactstrap components

import {
    Alert,
    Col,
    Row,
    Container,
    Button,
    Table,
    UncontrolledTooltip,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Carousel,
    CarouselItem,
    CarouselIndicators
    } from "reactstrap";

function TourneyGallery() {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const [singleSelect, setSingleSelect] = React.useState(null);
    const [slide, setSlide] = React.useState(true);
    const [collectionList,setCollectionList] = React.useState([]);
    const [imageItems,setImageItems] = React.useState([
        {
            src: require("assets/img/bg5.jpg"),
            altText: "Beach, United States",
            caption: "Beach, United States"
        },
        {
            src: require("assets/img/bg16.jpg"),
            altText: "Somewhere Beyond, United States",
            caption: "Somewhere Beyond, United States"
        },
        {
            src: require("assets/img/bg45.jpg"),
            altText: "Somewhere Beyond, United States",
            caption: "Somewhere Beyond, United States"
        },
    ]);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === imageItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? imageItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    //Selection Handler
    const handleChange = selectedOption => {
        //debugger;
        setSingleSelect(selectedOption);
        //Now lets get the array of all images for this collection
        var tempURL = rest.tourneyCollections;
        fetch(tempURL, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json;charset=UTF-8',
                'x-api-key': rest.apiKey
            })
        })
            .then(response => response.json())
            .then(data => {
                var temp_array = [];

                data.forEach((collection) => {
                    if(selectedOption.value === collection._id)
                        collection.Images.forEach((image) => {
                            temp_array.push({
                                src: image.Image,
                                altext: image.Value,
                                caption: image.Caption
                            });
                        });
                    console.log(temp_array);
                    setImageItems(temp_array);
                });
                debugger;
                // turn the JSON into a string then into an JS array
            })
            .catch(error => console.error(error));

    };
    //Lets setup the sidebar correctly
    React.useEffect(() => {
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("presentation-page");
            document.body.classList.remove("sidebar-collapse");
        };
    },[]);

    //Lets load all Collections
    React.useEffect(() => {
        //Lets load all Collections
        var tempURL = rest.tourneyCollections;
        fetch(tempURL, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json;charset=UTF-8',
                'x-api-key': rest.apiKey
            })
        })
            .then(response => response.json())
            .then(data => {
                var temp_array = [
                    {
                        value: "",
                        label: "Single Option",
                        isDisabled: true
                    }];
                data.forEach((collection) => {
                    temp_array.push({
                        value: collection._id,
                        label: collection.Title});
                });
                setCollectionList(temp_array);
                // turn the JSON into a string then into an JS array
            })
            .catch(error => console.error(error));
    },[]);

    return (
        <>
            <DropdownScrollNavbar />
            <div className="wrapper">
                <TourneyGalleryHeader />
                <div className="main">
                <div className="contact-content">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h2 className="title">Choose a Collection</h2>
                            <p className="description">
                                We have a lot of collections that you can view, pick one from the drop down and we will
                                get it started in carousel for you.<br></br>
                                <br></br>
                            </p>

                        <Select
                            className="react-select react-select-info"
                            classNamePrefix="react-select"
                            placeholder="Choose a Collection"
                            name="singleSelect"
                            closeMenuOnSelect={true}
                            value={singleSelect}
                            onChange={handleChange}
                            options={collectionList}
                        />
                        </Col>
                    </Row>
                        <Carousel activeIndex={activeIndex} next={next} previous={previous} pause={"hover"} slide={slide}>
                            <CarouselIndicators
                                items={imageItems}
                                activeIndex={activeIndex}
                                onClickHandler={goToIndex}
                            />
                            {imageItems.map(item => {
                                return (
                                    <CarouselItem
                                        onExiting={onExiting}
                                        onExited={onExited}
                                        key={item.src}
                                    >
                                        <div className="content-center text-center">
                                            <img src={item.src} alt={item.altText} />
                                            <div className="carousel-caption d-none d-md-block">
                                                <h5>{item.caption}</h5>
                                            </div>
                                        </div>
                                    </CarouselItem>
                                );
                            })}
                            <a
                                className="text-danger carousel-control-prev"
                                data-slide="prev"
                                href="#pablo"
                                onClick={e => {
                                    e.preventDefault();
                                    previous();
                                }}
                                role="button"
                            >
                                <i className="now-ui-icons arrows-1_minimal-left"></i>
                            </a>
                            <a
                                className="text-danger carousel-control-next"
                                data-slide="next"
                                href="#pablo"
                                onClick={e => {
                                    e.preventDefault();
                                    next();
                                }}
                                role="button"
                            >
                                <i className="now-ui-icons arrows-1_minimal-right"></i>
                            </a>
                        </Carousel>
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h3 className="text-danger title">Click on the left or right arrows to run the show! </h3>
                            <p className="description">
                                <br></br>
                                <br></br>
                            </p>
                        </Col>
                    </Row>
                </Container>
                </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default TourneyGallery;
