import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
// plugin that creates slider
import Slider from "nouislider";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import PresentationHeader from "components/Headers/PresentationHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

// sections for this page
import Components from "./presentation-sections/Components.js";
import BasicComponents from "./presentation-sections/BasicComponents.js";
import Cards from "./presentation-sections/Cards.js";
import Content from "./presentation-sections/Content.js";
import Sections from "./presentation-sections/Sections.js";
import Examples from "./presentation-sections/Examples.js";
import FreeDemo from "./presentation-sections/FreeDemo.js";
import Icons from "./presentation-sections/Icons.js";
import Image from "./presentation-sections/Image.js";
import Testimonials from "./presentation-sections/Testimonials.js";
import Pricing from "./presentation-sections/Pricing.js";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Collapse,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
function Presentation() {
  const [pills, setPills] = React.useState("1");
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  // slider states and functions
  const [sliderMin, setSliderMin] = React.useState(100);
  const [sliderMax, setSliderMax] = React.useState(880);
  React.useEffect(() => {
    document.body.classList.add("ecommerce-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("ecommerce-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <PresentationHeader />
        {/*
        <Components />
        <BasicComponents />
        <Cards />
        <Content />
        <Sections />
        <Examples />
        <FreeDemo />
        <Icons />
        <Image />
        <Testimonials />
        <Pricing />*/}
        <div
            className="testimonials-1 section-image"
            style={{
              backgroundImage: "url(" + require("assets/img/presentation-page/OutDoorSandNight23.jpg") + ")",
            }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">What is The Deep</h2>
                <h4 className="description text-white">
                  The Deep is a Sand Volleyball Club like no other.  We train in the deepest sand in Colorado with a
                  deep commitment to our athletes, friends and competitors.  The Deep offers outdoor and indoor sand
                  courts for year round training and play.  Our management and staff know beach volleyball  because we have
                  been playing and coaching beach volleyball for decades.
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                          alt="..."
                          className="img img-raised"
                          src={require("assets/img/presentation-page/ballshark.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <p className="card-description">
                      The Deep offers the deepest sand in Colorado with courts that are second to none!  The coaches
                      understand the game and offer one the most challenging sand conditioning programs I have ever
                      experienced.
                    </p>
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h4">Youth Player</CardTitle>
                    <p className="category">2020 Cohort 1</p>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                          alt="..."
                          className="img img-raised"
                          src={require("assets/img/presentation-page/ballshark3.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <p className="card-description">
                      The Deep allows me to advance my sand volleyball skills with year round indoor and outdoor sand
                      courts.  I love being able to play beach volleyball during the winter in Colorado!
                    </p>
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h4">Adult Player</CardTitle>
                    <p className="category">2021 Drop-In</p>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                          alt="..."
                          className="img img-raised"
                          src={require("assets/img/presentation-page/ballshark2.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <p className="card-description">
                      The coaches at The Deep VBC are top notch and my cohorts are small which allows me to get more play time with more one on one coaching!
                    </p>
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h4">Youth Player</CardTitle>
                    <p className="category">2020 Cohort 2</p>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Who we are?</h2>
                <h5 className="description">
                  We are volleyball professionals that are so passionate about the game that we built a sand volleyball
                  club, built top notch indoor and outdoor sand courts, train, play and coach youth players all to help grow the
                  game.  If we are not coaching, we are supporting drop-in and holding tourneys because we deeply care
                  about the game.  Our team comprises of all age groups and professions.  Each have their own burning passion
                  for beach volleyball.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-info"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                      className="image-container image-left"
                      style={{
                        backgroundImage:
                            "url(" + require("assets/img/presentation-page/OutsideSandCourt1.JPG") + ")",
                      }}
                  >
                    <p className="blockquote blockquote-info">
                      "The deeper the sand, the better the plan.  Sharks never give up, never give in and we dont
                      stop until the game is over, we are relentless." <br></br>
                      <br></br>
                      <small>-Rue Green</small>
                    </p>
                  </div>
                  <div
                      className="image-container image-left-bottom"
                      style={{
                        backgroundImage:
                            "url(" + require("assets/img/presentation-page/OutsideSandCourt10.jpg") + ")",
                      }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                      className="image-container image-right"
                      style={{
                        backgroundImage:
                            "url(" + require("assets/img/presentation-page/OutDoorSandBuild1.jpg") + ")",
                      }}
                  ></div>
                  <h3>
                    So why beach volleyball?
                  </h3>
                  <p>
                    Beach volleybal is a game that can be played your entire life.  It doesnt matter your age, the only
                    thing that matters is your attitude.  Beach volleyball is an incredible sport for conditioning.
                    Who likes running on a treadmill, walking on a stepper or going to gym?  Why not get in the sand and
                    get in shape?
                  </p>
                  <p>
                    For a start, beach volleyball is a great low impact sport.  With deep soft sand, the impact
                    on you body is little to none.  Life long friendships have come from the sport not to mention future
                    generations of players that are able to pick up the sport and fall in love with it.
                  </p>
                  <p>
                    Youth beach volleyball has and continues to make a come back.  For example, girls beach volleyball is now
                    a sanctioned sport for some universities with boys beach volleyball currently under consideration
                    to become a sanctioned college sport.  This means that getting our youth players into the game and
                    trained up to compete for those scholarships is critical.  With year round training and playing options, states
                    like Colorado can now offer athletes some fantastic options preparing them to compete for these new
                    scholarships.
                  </p>
                </Col>
                <Col md="5">
                  <div>
                    <a href="http://avpamerica.com" target="_blank" className="news-link">
                      <img
                          alt="..."
                          className="image-right"
                          src={require("assets/img/presentation-page/AVPA Logo.png")}
                      ></img>
                    </a>

                  </div>
                  <h3>
                    Proud member of AVP America for our organization and club perspective!
                  </h3>
                  <p>
                    By joining the AVP America family as a promoter and volleyball club, we can gain all the benefits
                    that AVP America offers our organization and its members.  Earn AVP points towards the AVP America
                    National Ranking system for all our tourneys and participate in our youth tourneys for chances to win
                    bids to national tournaments!  You can learn more about it by visiting:
                    <a href="http://avpamerica.com" target="_blank" class="news-link">
                    <p>AVP America</p>
                  </a>
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <FooterBlack />
      </div>
    </>
  );
}

export default Presentation;
