import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
//import DropdownFixedNavbar from "components/Navbars/DropdownFixedNavbar.js";
import DropdownScrollNavbar from "components/Navbars/DropdownScrollNavbar.js";
import FacilitiesHeader from "components/Headers/FacilitiesHeader.js";
import Footer from "components/Footers/FooterBlack.js";

function AboutUs() {
  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DropdownScrollNavbar />
      <div className="wrapper">
        <FacilitiesHeader />
        <div className="section">
          <div className="separator-line bg-info"></div>
          <div className="projects-5">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">We have many options</h2>
                  <h4 className="description">
                    The Deep provides private indoor and outdoor sand volleyball for our athletes
                  </h4>
                  <div className="section-space"></div>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/presentation-page/OutsideSandCourt2.jpg") + ")",
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Outdoor Sand Court</CardTitle>
                      <p className="card-description">
                        Providing a beautiful backdrop of Pikes Peak and amazing sunsets!
                      </p>
                      <Badge className="badge-neutral">Sporting 401 tons of white beach sand</Badge>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mr-auto" md="5">
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons sport_user-run"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        Plenty of Sand
                      </h4>
                      <p className="description">
                        Our outdoor court provides 401 tons of sand with an average depth of 4 feet of white beach sand.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons business_bulb-63"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Outdoor lights</h4>
                      <p className="description">
                        Our outdoor court has lights for playing in those cool summer nights
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons design-2_ruler-pencil"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Engineered to perfection</h4>
                      <p className="description">
                        Our outdoor court has been engineered to provide ample extended play, 12 foot high ball catch
                        nets, full court water drains and re-enforced led light retaining walls.  (Dont forget the good ole US
                        American flag!)
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col className="ml-auto mt-5" md="5">
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons business_globe"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Year round play and training</h4>
                      <p className="description">
                        Our indoor courts are open year round for our athletes to train and play on
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons business_bulb-63"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Led Lighting</h4>
                      <p className="description">
                        LED lighting providing excellent coverage
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons design-2_ruler-pencil"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Engineered to perfection</h4>
                      <p className="description">
                        Our indoor courts have been engineered to perfection sporting over 560
                        tons of white beach sand, full court drains, LED lighting and custom net, pads and lines.  We even
                        designed the court layout to support short or big court configurations.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mr-auto" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/presentation-page/IndoorSand2CourtPano.jpg") + ")",
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Indoor Sand Courts</CardTitle>
                      <p className="card-description text-white">
                        Indoor Sand Courts in our new 35 foot tall steel building
                      </p>
                      <Badge className="badge-neutral">Sporting 560 tons of white beach sand</Badge>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AboutUs;
