import React from "react";
import DropdownScrollNavbar from "components/Navbars/DropdownScrollNavbar.js";
import Footer from "components/Footers/FooterBlack.js";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Teams() {
  React.useEffect(() => {
    document.body.classList.add("pricing");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("pricing");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DropdownScrollNavbar />
      <div className="cd-section" id="teams">
        <div className="team-1 section-image"
             style={{
               backgroundImage: "url(" + require("assets/img/presentation-page/OutsideSandCourt4.jpg") + ")",
             }}

        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Our Coaches</h2>
                <h4 className="description">
                  Here are the coaches that work at The Deep.  While we can and do have other coaches help our athletes
                  out, the following list are regular faces you will see on our courts!
                </h4>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col className="ml-1" md="4">
                <Card className="card-profile ">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/presentation-page/rue_coach.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <CardTitle tag="h3">Rue Green</CardTitle>
                    <h6 className="category text-info">Head Sand / Conditioning Coach</h6>
                    <p className="card-description">
                      Rue has been playing indoor and beach sand volleyball for over 30 years.  Early in his career
                      Rue won several double tournanments throughout Colorado and even competed in several AVP qualifiers
                      including Hermosa Beach Open, Chicago Open, and The Manhattan Beach Open.  Rue has coached sand and indoor club teams
                      as well as his own children who have gone on to play at college and open level contests. Rue also is responsible for
                      creating and implementing The Deep's one of a kind sand conditioning program that helps condition
                      athletes and prepares them to compete.
                    </p>
                    <CardFooter>
                      <Button
                          className="btn-icon btn-round"
                          color="info"
                          href="http://www.linkedin.com/in/guruegreen"
                          target="_blank"
                      >
                        <i className="fab fa-linkedin"></i>
                      </Button>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              {/*
              <Col className="mr-1" md="4">
                <Card className="card-profile ">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                          alt="..."
                          className="img img-raised"
                          src={require("assets/img/presentation-page/Ian-bio2.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <CardTitle tag="h3">Ian Carlson</CardTitle>
                    <h6 className="category text-info">Sand / Conditioning Coach</h6>
                    <p className="card-description">
                      Ian has a small indoor career playing at Wentworth institute of technology earning 1st team all conference and making the all region team his sophomore year. However, he will be transferring to ASU in the fall of 2021 to finish his computer science degree
                      and play more beach volleyball. Ian started playing beach at 12 years old and has not stopped since, playing his first open tournament at 16 and his first open win at 19, racking up a total of 7 open wins. Ian trains, practices, and lifts for volleyball at
                      least 5 days a week with hopes of competing at an AVP level within the next few years. He also beats Sean and Jake in every tournament.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              */}
              <Col className="mr-1" md="4">
                <Card className="card-profile ">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/presentation-page/jake3.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <CardTitle tag="h3">Jake Green</CardTitle>
                    <h6 className="category text-info">Sand / Conditioning Coach</h6>
                    <p className="card-description">
                      Jake started playing volleyball with his father when he was 7 years old and fell in love with the game
                      ever since. He trains constantly with his sand partner to improve his skill and his passion for the game.
                      Jake coached at Flourish Beach last year and got to work alongside some of the best players in the game.
                      He has played quite a few of open tournaments with his sand partner Sean Carlson. Jake and Sean spend
                      their time training together and getting better at the sport. He is going to ACC and soon will be going
                      into the police academy to work his way to Homicide Detective. His love for the game will make him a
                      great coach for The Deep.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              {/*
              <Col className="mr-1" md="4">
                <Card className="card-profile ">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                          alt="..."
                          className="img img-raised"
                          src={require("assets/img/presentation-page/sean-bio.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <CardTitle tag="h3">Sean Carlson</CardTitle>
                    <h6 className="category text-info">Sand / Conditioning Coach</h6>
                    <p className="card-description">
                      Sean has played club volleyball for over 4 years including competing on High School indoor teams. Sean recently played on the number one ranked boys indoor team
                      in Colorado. Sean also has been playing sand volleyball since he could pass a ball, playing with his entire family.  Sean currently trains and competes at an Open
                      skill level; training with other Open Men players in Colorado.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              */}
              <Col className="ml-1 mt-4" md="4">
                <Card className="card-profile ">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/presentation-page/kyla_coach2.jpg")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                  <CardTitle tag="h3">Kyla Green</CardTitle>
                  <h6 className="category text-info">Sand Coach</h6>
                  <p className="card-description">
                    Kyla has been playing volleyball for over 10 years.  She has competed in both indoor and outdoor
                    tournaments and played over eight years in club volleyball.  Kyla attended college on a volleyball
                    scholarship as an outside hitter.  Kyla currently attends Colorado Mesa University and is completing
                    her degree in Biology.
                  </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Teams;
