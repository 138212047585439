import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/Navbars/DropdownScrollNavbar.js";
import AnnouncementsHeader from "components/Headers/AnnouncementsHeader.js";
import Footer from "components/Footers/FooterBlack.js";

function Announcements() {
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("blog-posts");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-posts");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DropdownScrollNavbar />
      <div className="wrapper">
        <AnnouncementsHeader />
        <div className="main">
          <Container>
            <div className="section">
              <h3 className="title text-center">
                Announcements
              </h3>
              <br></br>
              <Row>
                <Col md="4">
                  <Card className="card-plain card-blog">
                    <div className="card-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded img-raised"
                          src={require("assets/img/presentation-page/OutsideSandCourt2.jpg")}
                        ></img>
                      </a>
                    </div>
                    <CardBody>
                      <h6 className="category text-success">
                        <i className="now-ui-icons business_bank"></i>{" "}
                        Facilities (August 2020)</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Outdoor Sand Court gets some major upgrades!
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        Our outdoor sand court is now sporting a new rock retaining wall and border, with LED lights and a brand new lighted American Flag pole.  We also added
                        new catch net corners and raised the ball catch nets to over 12 feet.{" "}
                        {/*}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Check out the Project here!
                        </a>*/}
                      </p>
                      <div className="author">
                        <img
                          alt="..."
                          className="avatar img-raised"
                          src={require("assets/img/presentation-page/rue.jpg")}
                        ></img>
                        <span>Rue Green</span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-plain card-blog">
                    <div className="card-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded img-raised"
                          src={require("assets/img/presentation-page/IndoorSand2CourtPano.jpg")}
                        ></img>
                      </a>
                    </div>
                    <CardBody>
                      <h6 className="category text-success">
                        <i className="now-ui-icons business_bank"></i>{" "}
                        Facilities (Oct 2020)</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Build started and completed of our new Indoor Sand Courts
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        The Deep now has indoor sand courts for our athletes to train on.  The new
                        building offers 35 foot ceilings with enough room for two sand courts.  Check out our projects
                        page for a quick write up of the project and more pictures{" "}
                        {/*
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Read More
                        </a>*/}
                      </p>
                      <div className="author">
                        <img
                          alt="..."
                          className="avatar img-raised"
                          src={require("assets/img/presentation-page/rue.jpg")}
                        ></img>
                        <span>Rue Green</span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-plain card-blog">
                    <div className="card-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                            alt="..."

                            src={require("assets/img/presentation-page/AVPA Logo.png")}
                        ></img>
                      </a>
                    </div>
                    <CardBody>
                      <h6 className="category text-info">
                        <i className="now-ui-icons sport_user-run"></i>{" "}
                        AVP America Promoter and Club (Sept 2022)
                      </h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          We are now part of the AVP family as an Official AVP America Volleyball Club and Promoter!
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        By joining the AVP America Affilate program, our club can now offer tournaments for all ages.  We can also offer AVP points for the AVP
                        America National Ranking system for all our tourneys!{" "}
                        {/*
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Read More
                        </a>*/}
                      </p>
                      <div className="author">
                        <img
                            alt="..."
                            className="avatar img-raised"
                            src={require("assets/img/presentation-page/rue.jpg")}
                        ></img>
                        <span>Rue Green</span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-plain card-blog">
                    <div className="card-image">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded img-raised"
                          src={require("assets/img/presentation-page/TheDeepNets.jpg")}
                        ></img>
                      </a>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                            alt="..."
                            className="img rounded img-raised"
                            src={require("assets/img/presentation-page/TheDeepPads.jpg")}
                        ></img>
                      </a>
                    </div>
                    <CardBody>
                      <h6 className="category text-info">
                        <i className="now-ui-icons sport_user-run"></i>{" "}
                        Gear Upgrades (March 2021)
                      </h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          New custom Pads and Nets for our indoor and outdoor courts!
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        We are upgrading our indoor and outdoor pads and nets to provide a more custom
                        look and feel to our programs.  We also are adding new net "tensioning" systems to allow for
                        net height adjustments without any hassel.{" "}
                        {/*
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Read More
                        </a>*/}
                      </p>
                      <div className="author">
                        <img
                          alt="..."
                          className="avatar img-raised"
                          src={require("assets/img/presentation-page/rue.jpg")}
                        ></img>
                        <span>Rue Green</span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Announcements;
