import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick


// core components
//import DropdownScrollNavbar from "components/Navbars/DropdownScrollNavbar.js";
import DropdownScrollNavbar from "../components/Navbars/DropdownScrollNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import rest from "API/api";

// reactstrap components
import {
  Col,
  Container,
  Row,
  UncontrolledAlert,
    Alert
} from "reactstrap";


function DropinCalendar() {
  const [events,setEvents] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [eventTitle, setEventTitle] =  React.useState("");
  const [eventType, setEventType] =  React.useState("");
  const [eventLocation, setEventLocation] =  React.useState("");
  const [eventDescription, setEventDescription] =  React.useState("");

  React.useEffect(() => {
        if(visible)
            window.scrollTo(0, 0)
  });

    //Lets setup the sidebar correctly
    React.useEffect(() => {
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("presentation-page");
            document.body.classList.remove("sidebar-collapse");
        };
    },[]);


  //Lets load the calendar
    const selectedEvent = (info) => {
        //debugger;
        info.jsEvent.preventDefault(); // don't let the browser navigate
        /*
        alert("Type: " + info.event._def.extendedProps.type + "\n" +
            "Title: " + info.event._def.title + "\n" +
            "Location: " + info.event._def.extendedProps.location + "\n" +
            "Description: " + info.event._def.extendedProps.description);

        */
        setVisible(true);
        setEventType(info.event._def.extendedProps.type);
        setEventTitle(info.event._def.title);
        setEventLocation(info.event._def.extendedProps.location);
        //Lets remove the <br /> from the string that Google provides for the description
        setEventDescription(info.event._def.extendedProps.description.substring(0, info.event._def.extendedProps.description.length - 6));
    };
    const onDismiss = () => {
        setVisible(false);
        //setEventDetail(false);
    };
    /*
        this.setState({
            alert: (
                <SweetAlert
                    title={"Event Information"}
                    style={{display: "block",fontSize: "medium"}}
                    onConfirm={this.hideAlert}
                    confirmBtnBsStyle="info"
                >
                    <span style={{color:'#CD5C5C', fontWeight:"bold", fontSize: "small"}}>
                        <small>Event Type: {event.type}</small><br />
                        <small>Summary: {event.title}</small><br />
                        <small>Location: {event.location}</small><br />
                        <small>Description: {event.description}</small><br />
                        <small>Start: {event.start.toString()}</small><br />
                        <small>End: {event.end.toString()}</small><br />
                    </span>
                </SweetAlert>
            )
        });*/

  const myevents = [];

  let tempURL = `https://www.googleapis.com/calendar/v3/calendars/${rest.calendarDropin}/events?key=${rest.apiKeyGoogleCalendar}`;
  fetch(tempURL, {
    method: 'GET'
  })
      .then(response => response.json())
      .then(data => {
        data.items.map((event) => {
          let tempStart = new Date(event.start.date || event.start.dateTime);
          let tempEnd = new Date(event.end.date || event.end.dateTime);
          myevents.push({
            start: tempStart,
            end: tempEnd,
            title: event.summary,
            location: event.location,
            description: event.description,
            type: "Dropin",
            color: "blue",
          })
        });
        setEvents(myevents);
      })
      .catch(error => console.error(error));
  return (
    <>
      <DropdownScrollNavbar />
      <div className="wrapper">
          <div
              className="features-7 section-image"

          >
              <Alert color="info" isOpen={visible}>
                  <div className="container">
                      <div className="alert-icon">
                          <i className="now-ui-icons ui-1_calendar-60"></i>
                      </div>
                      <h4 className="alert-heading">Event Details</h4>
                      <hr />
                      <p className="mb-0">
                          <span style={{color:'white', fontWeight:"bold", fontSize: "small"}}>
                            <h5>Event Type: {eventType}</h5>
                            <h5>Event Ttile: {eventTitle}</h5>
                            <h5>Location: {eventLocation}</h5>
                            <h5>Description: {eventDescription}</h5>
                        </span>
                      </p>
                      <button
                          type="button"
                          className="close"
                          aria-label="Close"
                          onClick={onDismiss}
                      >
                          <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                          </span>
                      </button>
                  </div>
              </Alert>
          <Container fluid>
              <Row>

                <Col className="mr-auto ml-auto" md="8">

                  <FullCalendar

                      plugins={[dayGridPlugin, timeGridPlugin,interactionPlugin]}
                      initialView={"dayGridMonth"}
                      events={events}
                      headerToolbar={{
                        start: 'title', // will normally be on the left. if RTL, will be on the right
                        center: 'dayGridMonth timeGridWeek timeGridDay',
                        end: 'today prev,next' // will normally be on the right. if RTL, will be on the left
                      }}
                      editable={true}
                      eventClick={selectedEvent}
                  />

                </Col>
              </Row>
          </Container>
          </div>
      </div>
        {/*
        <Components />
        <BasicComponents />
        <Cards />
        <Content />
        <Sections />
        <Examples />
        <FreeDemo />
        <Icons />
        <Image />
        <Testimonials />
        <Pricing />*/}
        <FooterBlack />
    </>
  );
}

export default DropinCalendar;
