import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/Navbars/DropdownScrollNavbar.js";
import PhilosphyHeader from "components/Headers/PhilosphyHeader.js";
import Footer from "components/Footers/FooterBlack.js";

function Philosophy() {
  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DropdownScrollNavbar />
      <div className="wrapper">
        <PhilosphyHeader />
        <div className="section">
          <div className="about-office">
            <Container>
              <Row className="text-center">
                <Col className="ml-auto mr-auto" md="5">
                  <div>
                    <a href="http://avpamerica.com" target="_blank" className="news-link">
                      <img
                          alt="..."
                          className="image-right"
                          src={require("assets/img/presentation-page/AVPA Logo.png")}
                      ></img>
                    </a>

                  </div>
                  <h3>
                    Proud member of AVP America for our organization and club perspective!
                  </h3>
                  <p>
                    By joining the AVP America family as a promoter and volleyball club, we can gain all the benefits
                    that AVP America offers our organization and its members.  Earn AVP points towards the AVP America
                    National Ranking system for all our tourneys and participate in our youth tourneys for chances to win
                    bids to national tournaments!  You can learn more about it by visiting:
                    <a href="http://avpamerica.com" target="_blank" class="news-link">
                      <p>AVP America</p>
                    </a>
                  </p>
                </Col>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title">The sand is our second home</h2>
                  <h4 className="description">
                    <p>
                    Our passion is helping to grow the game.  We accomplish this by offering training programs for youth
                    and adult players of all ages and skill level.  We work on keeping the cohorts small to maximize the
                    one on one interaction between our athletes and our coaching staff. Smaller cohorts also allow for
                    more play and training time for the athletes and less standing around watching others play.
                    </p>
                    <p>
                    In addition to growing the game, The Deep also sponsors tournaments for athletes to test their skills
                    in competitive play.  These tournaments are offered throughout the year providing cash and swag for
                      the winning teams.
                    </p>
                    <p>
                      At The Deep, we focus our efforts on our athletes and strive to make each of them successful by
                      pushing their own physical and mental limits, raising their own expectation of performance. The Deep
                      also provides one on one individual training sessions for those athletes that want some quality one
                      on one sand time to focus on specific parts of their beach game.
                    </p>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/presentation-page/cohort1pic1.jpg")}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/presentation-page/cohort1pic2.jpg")}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/presentation-page/cohort1pic3.jpg")}
                  ></img>
                </Col>
                <Col md="6">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/presentation-page/SandBalls1.jpg")}
                  ></img>
                </Col>
                <Col md="6">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/presentation-page/SandBalls2.jpg")}
                  ></img>
                </Col>
              </Row>
            </Container>
          </div>

        </div>
        <Footer />
      </div>
    </>
  );
}

export default Philosophy;
