/*eslint-disable*/
import React from "react";

// reactstrap components

import {
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselIndicators,
} from "reactstrap";

// core components
const items = [
    {
        src: "url(" + require("../../assets/img/presentation-page/OutsideSandCourt4.jpg") + ")",
        content: (
            <Row>
                <Col className="ml-auto mr-auto" md="8">
                    <h1 className="title">The Deep</h1>
                    <h4 className="description text-white">
                        <b>Relentless pursuit of elite sand volleyball training</b>
                    </h4>
                </Col>
            </Row>
        ),
        altText: "",
        caption: "",
    },
    {
        src: "url(" + require("../../assets/img/presentation-page/IndoorSand2CourtPano.jpg") + ")",
        content: (
            <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                    <h1 className="title">The Deep</h1>
                    <h4 className="description text-white">
                        <b>The Deeper the sand, the better the plan.</b>
                    </h4>
                </Col>
            </Row>
        ),
        altText: "",
        caption: "",
    },
    {
        src: "url(" + require("../../assets/img/presentation-page/OutsideSandCourt1.JPG") + ")",
        content: (
            <Row>
                <Col className="ml-auto mr-auto" md="8">
                    <h1 className="title">The Deep</h1>
                    <h4 className="description text-white">
                        <b>Lets get in the sand!</b>
                    </h4>
                </Col>
            </Row>
        ),
        altText: "",
        caption: "",
    },
    {
        src: "url(" + require("../../assets/img/presentation-page/OutDoorSandNight23.jpg") + ")",
        content: (
            <Row>
                <Col className="ml-auto mr-auto" md="8">
                    <h1 className="title">The Deep</h1>
                    <h4 className="description text-white">
                        <b>Lets get in the sand!</b>
                    </h4>
                </Col>
            </Row>
        ),
        altText: "",
        caption: "",
    },
    {
        src: "url(" + require("../../assets/img/presentation-page/OutDoorSandNight24.jpg") + ")",
        content: (
            <Row>
                <Col className="ml-auto mr-auto" md="8">
                    <h1 className="title">The Deep</h1>
                    <h4 className="description text-white">
                        <b>Lets get in the sand!</b>
                    </h4>
                </Col>
            </Row>
        ),
        altText: "",
        caption: "",
    },
    {
        src: "url(" + require("../../assets/img/presentation-page/IndoorSandCourt2Balls.jpg") + ")",
        content: (
            <Row>
                <Col className="ml-auto mr-auto" md="8">
                    <h1 className="title">The Deep</h1>
                    <h4 className="description text-white">
                        <b>Lets get in the sand!</b>
                    </h4>
                </Col>
            </Row>
        ),
        altText: "",
        caption: "",
    },
];

function PresentationHeader() {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
  return (
    <>
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {items.map((item) => {
                return (
                    <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                    >
                        <div className="page-header header-filter">
                            <div
                                className="page-header-image"
                                style={{
                                    backgroundImage: item.src,
                                }}
                            ></div>
                            <div className="content-center text-center">{item.content}</div>
                        </div>
                    </CarouselItem>
                );
            })}
            <a
                className="left carousel-control carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                    e.preventDefault();
                    previous();
                }}
                role="button"
            >
                <span className="now-ui-icons arrows-1_minimal-left" />
                <span className="sr-only">Previous</span>
            </a>
            <a
                className="right carousel-control carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                    e.preventDefault();
                    next();
                }}
                role="button"
            >
                <span className="now-ui-icons arrows-1_minimal-right" />
                <span className="sr-only">Next</span>
            </a>
        </Carousel>
        {/*
      <div className="page-header clear-filter">
        <div className="rellax-header rellax-header-sky" data-rellax-speed="-4">

          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/presentation-page/nuk-pro-back-sky.jpg") +
                ")",
            }}
          ></div>
        </div>
        <div
          className="rellax-header rellax-header-buildings"
          data-rellax-speed="0"
        >
          <div
            className="page-header-image page-header-city"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/presentation-page/OutsideSandCourt2.jpg") +
                ")",
            }}
          ></div>
        </div>

        <div className="rellax-text-container rellax-text">
          <h1 className="h1-seo" data-rellax-speed="-1">
            The Deep
          </h1>
          <div className="pro"><a href="http://invisionapp.com/?ref=creativetim" target="_blank">
            <img
                alt="..."
                className="invision-logo"
                src={require("assets/img/presentation-page/TheDeepSharksLogo.jpg")}
            ></img>
          </a></div>
        </div>*/}
        {/*
        <h6
          className="category category-absolute rellax-text"
          data-rellax-speed="-1"
        >
          Designed  and Coded by{" Rue Green"}
          {/*
          <a href="http://invisionapp.com/?ref=creativetim" target="_blank">
            <img
              alt="..."
              className="invision-logo"
              src={require("assets/img/invision-white-slim.png")}
            ></img>
          </a>
          . Coded by{" Rue Greem"}
          <a
            href="https://www.gurueconsulting.com?ref=nuk-pro-react-presentation-header"
            target="_blank"
          > {/*
            <img
              alt="..."
              className="creative-tim-logo"
              src={require("assets/img/creative-tim-white-slim2.png")}
            ></img>
          </a>
          .
        </h6>
      </div>*/}
    </>
  );
}

export default PresentationHeader;
