//Global exports for API endpoints for EAYard API Gateway
//module.exports.endpoint = 'http://localhost:3002/api/v1/CXScoreCardInsurance';

//Use this entry for hosting on GuRue Consulting or main website adjust accordingly
//exports.loginUser = 'http://consent.gurueconsulting.com/api/v1/Consent/login';
//exports.getUserSalt = 'http://consent.gurueconsulting.com/api/v1/Consent/getUserSalt';
//exports.tenants = 'http://consent.gurueconsulting.com/api/v1/Consent/tenants';

//exports.loginUser = 'http://192.168.2.147:3002/api/v1/CXScoreCardInsurance/login';
//Use this entry when testing locally with API Gateway running on Local Host
/*
exports.loginUser = 'http://localhost:8083/api/v1/Consent/login';
exports.getUserSalt = 'http://localhost:3002/api/v1/Consent/getUserSalt';
exports.loginKioskUser = 'http://localhost:3002/api/v1/Consent/loginkioskuser';
exports.getKioskUserSalt = 'http://localhost:3002/api/v1/Consent/getkioskusersalt';
exports.tenants = 'http://localhost:3002/api/v1/Consent/tenants';
exports.artists = 'http://localhost:3002/api/v1/Consent/tenants/artists';
exports.artist = 'http://localhost:3002/api/v1/Consent/tenants/artist';
exports.clauses = 'http://localhost:3002/api/v1/Consent/tenants/clauses';
exports.clause = 'http://localhost:3002/api/v1/Consent/tenants/clause';
exports.consents = 'http://localhost:3002/api/v1/Consent/tenants/consents';
exports.consent = 'http://localhost:3002/api/v1/Consent/tenants/consent';
exports.kioskusers = 'http://localhost:3002/api/v1/Consent/tenants/kioskusers';
exports.kioskuser = 'http://localhost:3002/api/v1/Consent/tenants/kioskuser';
exports.includedartists = 'http://localhost:3002/api/v1/Consent/tenants/consent/artists';
exports.includedartistscombined = 'http://localhost:3002/api/v1/Consent/tenants/consent/artists/combined';
exports.includedartist = 'http://localhost:3002/api/v1/Consent/tenants/consent/artist';
exports.includedclauses = 'http://localhost:3002/api/v1/Consent/tenants/consent/clauses';
exports.includedclausescombined = 'http://localhost:3002/api/v1/Consent/tenants/consent/clauses/combined';
exports.includedclause = 'http://localhost:3002/api/v1/Consent/tenants/consent/clause';
exports.submissions = 'http://localhost:3002/api/v1/Consent/submissions';
exports.submission = 'http://localhost:3002/api/v1/Consent/submissions/submission';
exports.register = 'http://localhost:3002/api/v1/Consent/register';
exports.subscriptions = 'http://localhost:3002/api/v1/Consent/subscriptions';
exports.subscription = 'http://localhost:3002/api/v1/Consent/subscriptions/subscription';
*/
//exports.sendmail = 'http://localhost:8084/api/v1/thedeep/sendmail';
exports.sendmail = 'http://www.thedeepvbc.com/api/v1/thedeep/sendmail';

//Cohort, Tourney and Collection and images REST endpoints
exports.cohortCollections = 'http://www.thedeepvbc.com/api/v1/thedeep/cohortgallery';
exports.tourneyCollections = 'http://www.thedeepvbc.com/api/v1/thedeep/tourneygallery';
exports.generalCollections = 'http://www.thedeepvbc.com/api/v1/thedeep/generalgallery';
exports.events = 'http://www.thedeepvbc.com/api/v1/thedeep/register/listevents';

//General API key for accessing server side REST calls via apigateway
exports.apiKey = '9d61a8fc-3376-4234-ae3e-c194d7d4bd34';
exports.email = "thedeepvbc@gmail.com";

//Keys and IDs for Google Calendar owned by eayardworkschedule@gmail.com
exports.apiKeyGoogleCalendar = "AIzaSyCQ87AtU_SXgr5KeGibEfVt7C7wseK1il4";
exports.calendarTheDeep = "thedeepvbc@gmail.com";
exports.calendarBirthdays = "#contacts@group.v.calendar.google.com";
exports.calendarPractice = "7fmt4p1kgg8i77n7h3e0nk3pbo@group.calendar.google.com";
exports.calendarTourney = "u69fddm09nfa6a8cam0gl4rjuc@group.calendar.google.com";
exports.calendarDropin = "nms6dsmks9kk478elpcgojb2j4@group.calendar.google.com";

