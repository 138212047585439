import React from "react";
import rest from "API/api";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
  FormFeedback,
  FormText
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/Navbars/DropdownScrollNavbar.js";
import PricingHeader from "components/Headers/PricingHeader.js";
import Footer from "components/Footers/FooterBlack.js";


function Pricing() {

  const [firstNameFocus, setFirstNameFocus] = React.useState(false);
  const [lastNameFocus, setLastNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [visible, setAlertVisible] = React.useState(false);
  const [message, setAlertMessage] = React.useState("");
  const [color, setAlertColor] = React.useState("");
  const onDismiss = () => setAlertVisible(false);

  //Validation hooks and functions
  const [validEmail, setValidEmail] = React.useState("");
  const [validFName, setValidFName] = React.useState("");
  const [validLName, setValidLName] = React.useState("");

  const validateFName = (e) => {
    //console.log("Fname is " +  e.target.value);
    if(e.target.value)
      setValidFName("has-success");
    else
      setValidFName("has-danger");

  };
  const validateLName = (e) => {
    if(e.target.value)
      setValidLName("has-success");
    else
      setValidLName("has-danger");

  };

   const validateEmail = (e) => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(e.target.value)) {
      setValidEmail("has-success");
    } else {
      setValidEmail("has-danger");
    }
  };

  const SendEmail = (e) => {
    e.preventDefault();
    //console.log("I am in sendemail");

      var message = '<html><body>';
      message = message + "<h2> You have a new inquiry from The Deep VBC Website:</h2><br><br>"
      //message = message + '<img src="//css-tricks.com/examples/WebsiteChangeRequestForm/images/wcrf-header.png" alt="Website Change Request" />';
      message = message + '<table rules="all" style="border-color: #666;" cellpadding="10">';
      message = message + "<tr style='background: #eee;'><td><strong>" + "Contact Request" + "</strong> </td><td>" + "Details" + "</td></tr>";
      message = message + "<tr><td><strong>Customer Full Name:</strong> </td><td>" + document.getElementById('fname').value + " " + document.getElementById('lname').value + "</td></tr>";
      message = message + "<tr><td><strong>Customer Email:</strong> </td><td>" + document.getElementById('email').value + "</td></tr>";
      message = message + "<tr><td><strong>Customer Message:</strong> </td><td>" + document.getElementById('message').value + "</td></tr>";
      message = message + "</table>";
      message = message + "</body></html>";
      //console.log(message);
      fetch(rest.sendmail,  {
        headers: new Headers({
          'Content-Type': 'application/json;charset=UTF-8',
          'x-api-key': rest.apiKey,
        }),
        method: 'POST',

        body: JSON.stringify({
          to: rest.email,
          from: document.getElementById('email').value,
          subject: "New Contact Request",
          message: message
        })
      })
          .then(response => response.json())
          .then(data => {
            console.log(data);
            if(data.mailSent === "successful") {
              setAlertVisible(true);
              setAlertMessage("We have sent your message to our team!");
              setAlertColor("success");
            }
            else {
              setAlertVisible(true);
              setAlertMessage("We could not send your message to our team, try again!");
              setAlertColor("danger");
            }
          })
          .catch(error => {
            console.error(error);

          });

};




  React.useEffect(() => {
    document.body.classList.add("pricing");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("pricing");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DropdownScrollNavbar />
      <div className="wrapper">
        <PricingHeader />
        <div className="main">
          <div className="pricing-4">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <h4 className="description">
                    The Deep offers some options to get your athlete started.  Our cohorts run all year long offering weekly pratices.
                    We also offer 1-1 training on an individual or smaller group basis.
                    ALL participates must sign our participation waiver so if you have not done so, please
                    do so before signing up!
                  </h4>
                  <div className="section-space"></div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Card className="card-pricing card-plain">
                    <CardBody>
                      <h6 className="category">The Deep VBC Online Waiver</h6>
                      <div className="icon icon-info">
                        <i className="now-ui-icons files_paper"></i>
                      </div>
                      <CardTitle tag="h3">
                        <small>Waiver</small>
                      </CardTitle>
                      <ul>
                        <li><a href =  "https://waiver.smartwaiver.com/w/5f9304eda663f/web/" target = "_blank">The Deep VBC Online Waiver</a></li>
                      </ul>
                      {/*
                      <Button
                        className="btn-round"
                        color="info"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Sign up!
                      </Button>
                      */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Card className="card-pricing card-plain">
                    <CardBody>
                      <h6 className="category">Cohort Session</h6>
                      <div className="icon icon-info">
                        <i className="now-ui-icons business_briefcase-24"></i>
                      </div>
                      <CardTitle tag="h3">
                        <small>$</small>
                        25 per hr
                      </CardTitle>
                      <ul>
                        <li>One 2-3 hour sessions per week</li>
                        <li>Weekly sessions, pay per hour model</li>
                        <li>7-12 athletes per session</li>
                      </ul>
                      <Button
                        className="btn-round"
                        color="info"
                        href="https://dashboard.thedeepvbc.com/#/register" target = "_blank"

                      >
                        Sign up!
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-pricing card-plain">
                    <CardBody>
                      <h6 className="category">1 on 1 Training</h6>
                      <div className="icon icon-danger">
                        <i className="now-ui-icons design_palette"></i>
                      </div>
                      <CardTitle tag="h3">
                        <small>$</small>
                        50 per hr
                      </CardTitle>
                      <ul>
                        <li>1 hour sessions for 1 athlete</li>
                        <li>Bring a partner (max 4 athletes).</li>
                        <li>Discounted per grp size, 25 per hour > 1 athlete</li>
                      </ul>
                      <Button
                        className="btn-round"
                        color="danger"
                        href="https://dashboard.thedeepvbc.com/#/register" target = "_blank"
                      >
                        Sign up!
                      </Button>

                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-pricing card-plain">
                    <CardBody>
                      <h6 className="category">Tournaments</h6>
                      <div className="icon icon-warning">
                        <i className="now-ui-icons design_palette"></i>
                      </div>
                      <CardTitle tag="h3">
                        <small>Based on format</small>

                      </CardTitle>
                      <ul>
                        <li>The Deep Sponsored Sand Tournaments </li>
                        <li>Different formats: coed, mens, dino, kings, etc.</li>
                        <li>Price varies based on format</li>
                      </ul>
                      <Button
                          className="btn-round"
                          color="warning"
                          href="https://dashboard.thedeepvbc.com/#/register" target = "_blank"
                      >
                        Sign up!
                      </Button>

                    </CardBody>
                  </Card>
                </Col>
                <Col md="12">
                  <Card className="card-pricing card-plain">
                    <CardBody>
                      <h6 className="category">Drop-in</h6>
                      <div className="icon icon-warning">
                        <i className="now-ui-icons sport_user-run"></i>
                      </div>
                      <CardTitle tag="h3">
                        <small>$</small>
                        10
                      </CardTitle>
                      <ul>
                        <li>Typically 3 hours in length</li>
                        <li>Designated Dropin times</li>
                        <li>Drop In calendar under Events</li>
                        <li>Donation of $10 per person</li>
                      </ul>
                      <Button
                        className="btn-round"
                        color="warning"
                        href="https://www.venmo.com" target = "_blank"
                      >
                        Donate via Venmo!
                      </Button>
                      <Button
                          className="btn-round"
                          color="info"
                          href="https://www.paypal.com" target = "_blank"
                      >
                        Donate via PayPal!
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Card className="card-pricing card-plain">
                    <CardBody>
                      <h6 className="category">Payment Methods</h6>
                      <div className="icon icon-success">
                        <i className="now-ui-icons business_money-coins"></i>
                      </div>
                      <CardTitle tag="h3">
                        <small>Below are our online partner payment options. We also provide complete and secure credit card processing via our online Registration tool located here:
                          <b><a href="https://dashboard.thedeepvbc.com/#/register" target = "_blank"> The Deep Registration Site</a>.</b> For major events, please use our registration
                          site, however for simple one off donations to our club feel free to use the partners below!
                        </small>
                      </CardTitle>
                      <ul>
                        <li><b>Venmo:</b> @Rue-Green</li>
                        <li><b>PayPal Friends and Family: rue@thedeepvbc.com </b> <img
                            alt="..."
                            className="img rounded img-raised"
                            src={require("assets/img/presentation-page/paypal-qrcode.png")}
                        ></img> </li>
                      </ul>
                      {/*
                      <Button
                          className="btn-round"
                          color="danger"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                      >
                        Get it Now
                      </Button>*/}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section section-frequently">
            <Container>
              <div className="text-center">
                <h3 className="title">Frequently Asked Questions</h3>
              </div>
              <Row>
                <Col className="ml-auto" md="4">
                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="now-ui-icons shopping_credit-card"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        Can I sign-up for a cohort, practice for only part of the time and only come when I am available?
                      </h4>
                      <p>
                        Yes, the cohort practices are setup for a specific day of the week and the pricing model is pay as you go per hour.
                        So you choose to come to an entire practice, part of a practice or skip a specific practice due to life events.  However, you only pay
                        for the each hour that you attend the practice.  This model has proven to be the most economical and practical method for our members.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mr-auto" md="4">
                  <div className="info info-horizontal">
                    <div className="icon icon-success">
                      <i className="now-ui-icons shopping_box"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        Is there any discount for signing up for multiple  sessions?
                      </h4>
                      <p>
                        No, since we use a pay as you practice model there is no discount for paying for practices in advance.  Avoiding advance payments
                        for future practices removes the risk of not being able to attend and losing your investment.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto" md="4">
                  <div className="info info-horizontal">
                    <div className="icon icon-success">
                      <i className="now-ui-icons business_money-coins"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        Which payment methods do you take?
                      </h4>
                      <p>
                        Currently we can take a personal check, cash, PayPal (F&F) and Venmo. For
                        online payments and contributions you can use the following ( I will be working on
                        adding direct payment buttons to this form to make it easier but for now you can access
                        the online payment site of your choice and make payments to one of these accounts:
                      </p>
                      <ul>
                        <li><b>Venmo:</b> @Rue-Green</li>
                        <li><b>PayPal Friends and Family: rue@thedeepvbc.com </b> <img
                            alt="..."
                            className="img rounded img-raised"
                            src={require("assets/img/presentation-page/paypal-qrcode.png")}
                        ></img> </li>
                      </ul>

                    </div>
                  </div>
                </Col>
                <Col className="mr-auto" md="4">
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons business_bulb-63"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        How are the cohorts structured skill wise?
                      </h4>
                      <p>While we work hard to try and place our athletes into cohorts with peers that have
                        similar skills, its not a perfect science. For athletes that we have never had in our program
                        we would like to provide a skills assessment similar to a tryout to determine your athletes current
                        skill level and potential.  This helps us place them in the correct cohort group and gives our coaches insight on
                        what skills we need to focus on for them.</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div
            className="contactus-1 section-image"
            style={{
              backgroundImage: "url(" + require("assets/img/bg27.jpg") + ")",
            }}
          >
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="5">
                  <h2 className="title">Get in Touch</h2>
                  <h4 className="description">
                    You need more information?
                  </h4>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons location_pin"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Find us at the office</h4>
                      <p className="description">
                        3980 County Road 146, <br></br>
                        Elizabeth, CO  80107 <br></br>
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons tech_mobile"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Give us a ring</h4>
                      <p className="description">
                        Rue Green <br></br>
                        (303) 324-9089 <br></br>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" md="6">
                  <Card className="card-contact card-raised">
                    <Form id="contact-form" method="post" role="form">
                      <CardHeader className="text-center">
                        <CardTitle tag="h4">Contact Us</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col className="pr-2" md="6">
                            <label>First name</label>
                            <InputGroup
                              className={
                                firstNameFocus ? "input-group-focus" : ""
                              }
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons users_circle-08"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                invalid={ validFName === 'has-danger' }
                                valid={ validFName === 'has-success' }
                                id="fname"
                                autoComplete="name"
                                placeholder="First Name..."
                                type="text"
                                onFocus={() => setFirstNameFocus(true)}
                                onBlur={() => setFirstNameFocus(false)}
                                onChange={validateFName}
                              ></Input>
                              <FormFeedback invalid="true">
                                Uh oh! We need your first name!
                              </FormFeedback>
                              <FormFeedback valid>
                                Cool name!
                              </FormFeedback>
                            </InputGroup>
                          </Col>
                          <Col className="pl-2" md="6">
                            <FormGroup>
                              <label>Last name</label>
                              <InputGroup
                                className={
                                  lastNameFocus ? "input-group-focus" : ""
                                }
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="now-ui-icons text_caps-small"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  invalid={ validLName === 'has-danger' }
                                  valid={ validLName === 'has-success' }
                                  id="lname"
                                  autoComplete="name"
                                  placeholder="Last Name..."
                                  type="text"
                                  onFocus={() => setLastNameFocus(true)}
                                  onBlur={() => setLastNameFocus(false)}
                                  onChange={validateLName}
                                ></Input>
                                <FormFeedback invalid="true">
                                  Uh oh! We need your last name!
                                </FormFeedback>
                                <FormFeedback valid>
                                  Cool last name!
                                </FormFeedback>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <label>Email address</label>
                          <InputGroup
                            className={emailFocus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons ui-1_email-85"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              invalid={ validEmail === 'has-danger' }
                              valid={ validEmail === 'has-success' }
                              id="email"
                              autoComplete="email"
                              placeholder="Email Here..."
                              type="email"
                              onFocus={() => setEmailFocus(true)}
                              onBlur={() => setEmailFocus(false)}
                              onChange={validateEmail}
                            ></Input>
                            <FormFeedback invalid="true">
                              Uh oh! Looks like there is an issue with your email.
                            </FormFeedback>
                            <FormFeedback valid>
                              That looks like a solid email address!
                            </FormFeedback>
                          </InputGroup>

                        </FormGroup>
                        <FormGroup>
                          <label>Your message</label>
                          <Input
                            id="message"
                            name="message"
                            rows="6"
                            type="textarea"
                          ></Input>
                          <FormText>Tell use what we can help you with.</FormText>
                        </FormGroup>
                        <Row>
                          <Col md="8">
                            <Button
                              className="btn-round pull-right"
                              color="info"
                              type="submit"
                              disabled = {(((validEmail === "has-success") && (validFName === "has-success") && (validLName ==="has-success")) ? false : true)}
                              onClick={SendEmail}
                            >
                              Send Message
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                    <Alert color={color} isOpen={visible} toggle={onDismiss}>
                      {message}
                    </Alert>
                  </Card>
                </Col>
              </Row>
            </Container>

          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Pricing;
