import React from "react";
import rest from "API/api";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col, Alert, FormFeedback, FormText,
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/Navbars/DropdownScrollNavbar.js";
import ContactUsHeader from "components/Headers/ContactUsHeader.js";
import Footer from "components/Footers/FooterBlack.js";

const MapWrapper = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: 39.390313 , lng: -104.591533 }}
      defaultOptions={{
        scrollwheel: false,
        styles: [
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [{ color: "#ffffff" }, { lightness: 17 }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 18 }],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 16 }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#dedede" }, { lightness: 21 }],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              { visibility: "on" },
              { color: "#ffffff" },
              { lightness: 16 },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              { saturation: 36 },
              { color: "#333333" },
              { lightness: 40 },
            ],
          },
          { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [{ color: "#fefefe" }, { lightness: 20 }],
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
          },
        ],
      }}
    >
      <Marker position={{ lat: 39.390313 , lng: -104.591533 }} />
    </GoogleMap>
  ))
);

function ContactUs() {
  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [numberFocus, setNumberFocus] = React.useState(false);

  const [visible, setAlertVisible] = React.useState(false);
  const [message, setAlertMessage] = React.useState("");
  const [color, setAlertColor] = React.useState("");
  const [number, setNumber] = React.useState("");
  const onDismiss = () => setAlertVisible(false);

  //Validation hooks and functions
  const [validEmail, setValidEmail] = React.useState("");
  const [validName, setValidName] = React.useState("");
  const [validNumber, setValidNumber] = React.useState("");
  const [validMessage, setValidMessage] = React.useState("");
  const prevnumber = usePrevious(number);

  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  };

  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }


  const validateNumber = (e) => {
    setNumber(normalizeInput(e.target.value,prevnumber));
    if((e.target.value) && (e.target.value.length === 14))
      setValidNumber("has-success");
    else if(!e.target.value)
      setValidNumber("has-danger");
    else
      setValidNumber("has-danger");
  };

  const validateName = (e) => {
    if(e.target.value)
      setValidName("has-success");
    else
      setValidName("has-danger");

  };
  const validateMessage = (e) => {
    if(e.target.value)
      setValidMessage("has-success");
    else
      setValidMessage("has-danger");

  };

  const validateEmail = (e) => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(e.target.value)) {
      setValidEmail("has-success");
    } else {
      setValidEmail("has-danger");
    }
  };

  const SendEmail = (e) => {
    e.preventDefault();
    //console.log("I am in sendemail");

    var message = '<html><body>';
    message = message + "<h2> You have a new inquiry from The Deep VBC Website:</h2><br><br>"
    //message = message + '<img src="//css-tricks.com/examples/WebsiteChangeRequestForm/images/wcrf-header.png" alt="Website Change Request" />';
    message = message + '<table rules="all" style="border-color: #666;" cellpadding="10">';
    message = message + "<tr style='background: #eee;'><td><strong>" + "Contact Request" + "</strong> </td><td>" + "Details" + "</td></tr>";
    message = message + "<tr><td><strong>Customer Name:</strong> </td><td>" + document.getElementById('name').value + "</td></tr>";
    message = message + "<tr><td><strong>Customer Phone Number:</strong> </td><td>" + document.getElementById('phone').value + "</td></tr>";
    message = message + "<tr><td><strong>Customer Email:</strong> </td><td>" + document.getElementById('email').value + "</td></tr>";
    message = message + "<tr><td><strong>Customer Message:</strong> </td><td>" + document.getElementById('message').value + "</td></tr>";
    message = message + "</table>";
    message = message + "</body></html>";
    //console.log(message);
    fetch(rest.sendmail,  {
      headers: new Headers({
        'Content-Type': 'application/json;charset=UTF-8',
        'x-api-key': rest.apiKey,
      }),
      method: 'POST',

      body: JSON.stringify({
        to: rest.email,
        from: document.getElementById('email').value,
        subject: "New Contact Request",
        message: message
      })
    })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          if(data.mailSent === "successful") {
            setAlertVisible(true);
            setAlertMessage("We have sent your message to our team!");
            setAlertColor("success");
          }
          else {
            setAlertVisible(true);
            setAlertMessage("We could not send your message to our team, try again!");
            setAlertColor("danger");
          }
        })
        .catch(error => {
          console.error(error);

        });

  };



  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DropdownScrollNavbar />
      <div className="wrapper">
        <ContactUsHeader />
        <div className="main">
          <div className="contact-content">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="5">
                  <h2 className="title">Send us a message</h2>
                  <p className="description">
                    You can contact us with anything related to our Club.
                    We'll get in touch with you as soon as possible. <br></br>
                    <br></br>
                  </p>
                  <Form id="contact-form" method="post" role="form">
                    <label>Your name</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        invalid={ validName === 'has-danger' }
                        valid={ validName === 'has-success' }
                        id="name"
                        aria-label="Your Name..."
                        autoComplete="name"
                        placeholder="Your Name..."
                        type="text"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                        onChange={validateName}
                      ></Input>
                      <FormFeedback invalid="true">
                        Uh oh! We need your first name!
                      </FormFeedback>
                      <FormFeedback valid>
                        Cool name!
                      </FormFeedback>
                    </InputGroup>
                    <label>Email address</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        invalid={ validEmail === 'has-danger' }
                        valid={ validEmail === 'has-success' }
                        id="email"
                        aria-label="Email Here..."
                        autoComplete="email"
                        placeholder="Email Here..."
                        type="email"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        onChange={validateEmail}
                      ></Input>
                      <FormFeedback invalid="true">
                        Uh oh! Looks like there is an issue with your email.
                      </FormFeedback>
                      <FormFeedback valid>
                        That looks like a solid email address!
                      </FormFeedback>
                    </InputGroup>
                    <label>Phone</label>
                    <InputGroup
                      className={numberFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons tech_mobile"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        invalid={ validNumber === 'has-danger' }
                        valid={ validNumber === 'has-success' }
                        id="phone"
                        autoComplete="number"
                        placeholder="(###) ###-####"
                        type="text"
                        onFocus={() => setNumberFocus(true)}
                        onBlur={() => setNumberFocus(false)}
                        onChange={validateNumber}
                        value={number}
                      ></Input>
                      <FormFeedback invalid="true">
                        Uh oh! Make sure phone number is in the format: (###) ###-####!
                      </FormFeedback>
                      <FormFeedback valid>
                        That looks like a solid phone number!
                      </FormFeedback>
                    </InputGroup>
                    <FormText>Just start typing, we will format the phone number for you as (###) ###-####.</FormText>
                    <FormGroup>
                      <label>Your message</label>
                      <Input
                        invalid={ validMessage === 'has-danger' }
                        valid={ validMessage === 'has-success' }
                        id="message"
                        name="message"
                        rows="6"
                        type="textarea"
                        onChange={validateMessage}
                      ></Input>
                      <FormFeedback invalid="true">
                        Uh oh! We need a message to know what we can do for you!
                      </FormFeedback>
                      <FormFeedback valid>
                        Cool, We now know how we can help you!
                      </FormFeedback>
                    </FormGroup>
                    <div className="submit text-center">
                      <Button
                        className="btn-raised btn-round"
                        color="info"
                        defaultValue="Contact Us"
                        disabled = {(((validEmail === "has-success") && (validName === "has-success") && (validNumber ==="has-success") && (validMessage ==="has-success")) ? false : true)}
                        onClick={SendEmail}
                        type="submit"
                      >
                        Contact Us
                      </Button>
                    </div>
                  </Form>
                  <Alert color={color} isOpen={visible} toggle={onDismiss}>
                    {message}
                  </Alert>
                </Col>
                <Col className="ml-auto mr-auto" md="5">
                  <div className="info info-horizontal mt-5">
                    <div className="icon icon-info">
                      <i className="now-ui-icons location_pin"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Find us in the sand!</h4>
                      <p>
                        3980 County Road 146, <br></br>
                       Elizabeth CO 80107, <br></br>
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons tech_mobile"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Give us a ring</h4>
                      <p>
                        Rue Green <br></br>
                        (303) 324-9089 <br></br>
                        Mon - Fri, 8:00am - 5:00pm MST
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="business_briefcase-24 now-ui-icons"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Legal Information</h4>
                      <p>
                        The Deep <br></br>
                        Sand Volleyball Club
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="big-map" id="contactUs2Map">
          <MapWrapper
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCQ87AtU_SXgr5KeGibEfVt7C7wseK1il4"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ContactUs;
